import {
  EVENT_FORBIDDEN,
  EVENT_NOT_FOUND,
  EVENT_NO_COMPANY,
} from "utils/event-manager/index";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";
import RequestConfig from "./models/request-config";
import ApiErrorType from "./enums/api-error-type";
import ApiResponse from "./models/api-response";
import { ApiError } from "./models/api-error";
import eventManager, { EVENT_UNAUTHORIZED } from "../event-manager";
import ApiResult from "./models/api-result";
import { Token } from "utils/helpers/encrypt-decrypt-user";
import i18next from "i18next";

export default class ApiProvider {
  private api: AxiosInstance;
  public constructor(config: RequestConfig) {
    this.api = axios.create(config);
    this.api.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param,
      headers: {
        ...param.headers,
        "Accept-Language": i18next.language,
        Authorization: `Bearer ${Token.Get()}`,
      },
    }));
  }

  public async request<T>(config: RequestConfig): Promise<ApiResponse<T>> {
    let result: ApiResponse<T> = { errorType: ApiErrorType.UNKNOWN };

    try {
      const response = await this.api.request<ApiResult<T>>(config);
      result = response.data;
    } catch (error: any) {
      result = this.handleError(error);
    } finally {
      return result;
    }
  }

  private handleError(error: AxiosError<ApiError>): ApiError {
    if (error.response) {
      // The request was made and the server responded with an error status code.
      let type: ApiErrorType;
      switch (error.response.status) {
        case 400:
          type = ApiErrorType.BAD_REQUEST;
          break;
        case 401:
          type = ApiErrorType.UNAUTHORIZED;
          eventManager.emit(EVENT_UNAUTHORIZED);
          break;
        case 403:
          type = ApiErrorType.FORBIDDEN;
          if (error.response.data.message === "This action is unauthorized.")
            eventManager.emit(EVENT_NO_COMPANY);
          else eventManager.emit(EVENT_FORBIDDEN);
          break;
        case 404:
          type = ApiErrorType.NOT_FOUND;
          eventManager.emit(EVENT_NOT_FOUND);
          break;
        case 409:
          type = ApiErrorType.CONFLICT;
          break;
        case 422:
          type = ApiErrorType.DATA_VALIDATION_FAILED;
          break;
        case 500:
          type = ApiErrorType.INTERNAL_SERVER_ERROR;
          break;
        default:
          type = ApiErrorType.UNKNOWN;
          break;
      }
      return { ...error.response.data, errorType: type };
    } else if (error.request) {
      // The request was made but no response was received.
      return { errorType: ApiErrorType.CONNECTION };
    } else {
      return { errorType: ApiErrorType.UNKNOWN };
    }
  }
}
