import { Button, Collapse, Result } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

const InternalError = ({ error }: any) => {
  console.log(window.location.hostname);
  return (
    <Result
      status="500"
      title="عذرا ، حدث خطأ ما."
      subTitle={
        <>
          <div>في حالة استمرار حدوث هذا الخطأ ، يرجى الاتصال بالفريق الفني</div>
        </>
      }
      extra={[
        <Button type="primary" onClick={() => window.location.replace("/")}>
          العودة للصفحة الرئيسية
        </Button>,
        <Button type="default" onClick={() => window.location.reload()}>
          تحديث الصفحة
        </Button>,
        <>
          {window.location.hostname === "localhost" && (
            <Collapse
              style={{ width: "50%", margin: "auto", marginTop: "10px" }}
              bordered={false}
            >
              <CollapsePanel key={"error"} header={"تفاصيل تقنية"}>
                <div style={{ direction: "ltr", textAlign: "left" }}>
                  {error?.error?.message}
                </div>
              </CollapsePanel>
            </Collapse>
          )}
        </>,
      ]}
    />
  );
};

export default InternalError;
