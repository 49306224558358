import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { DeviceUUID } from "device-uuid";
import { Token } from "utils/helpers/encrypt-decrypt-user";

const firebaseConfig = {
  apiKey: "AIzaSyACNNwtcMI-O22gysQmdy7SGm5_YfMvuSk",
  authDomain: "cyberx-f5d68.firebaseapp.com",
  projectId: "cyberx-f5d68",
  storageBucket: "cyberx-f5d68.appspot.com",
  messagingSenderId: "275668483030",
  appId: "1:275668483030:web:6061ea61f40aa0e3df4277",
};

const firebaseApp = initializeApp(firebaseConfig);

if (firebaseApp) {
}

/**
 * disable for localhost
 */
export const messaging = getMessaging(firebaseApp);
// export const messaging = "";

export const getTokenAsync = () => {
  return getToken(messaging, {
    vapidKey:
      "BC9X9tbfcNUteXzcDIUvxpBIijWNMIgjRrE_pOHbUsQyaj0LYus5xi0Z0eHljLgLJJ21m55jxzUtK6g8xQWhZGo",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        var uuid = new DeviceUUID().get();
        if (Token.Get() !== null)
          axios.post(
            process.env.REACT_APP_API_URL + "api/add-firebase-token",
            {
              firebase_token: currentToken,
              device_id: uuid,
              platform: "Web",
              version: `${process.env.REACT_APP_VERSION}`,
            },
            {
              headers: {
                Authorization: `Bearer ${Token.Get()}`,
              },
            }
          );
        return currentToken;
      }
    })
    .catch((err) => {
      console.log("Error firebase", err);
      // catch error while creating clieernt token
    });
};
