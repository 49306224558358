/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Menu,
  Dropdown,
  Popover,
  Drawer,
  Switch,
  Spin,
  Button,
  Badge,
  MenuProps,
  Space,
} from "antd";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  BellOutlined,
  CheckCircleOutlined,
  DownOutlined,
  LoadingOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import useNotifications from "hooks/query/notifications/useNotifications";
import { useWidth } from "utils/helpers/use-is-mobile";
import { QueryCache, QueryClient, useQueryClient } from "react-query";
import NewsModal, { NewsIcon } from "components/Modals/whatsNew";
import { Roles } from "utils/helpers/static_data";
import { hasPermission } from "components/permissionsGate";
import Color from "utils/helpers/export-sass-colors";
import ReadAllNotifications from "hooks/query/notifications/readAllNotifications";
import ReadOneNotification from "hooks/query/notifications/readOneNotification";
import useLogout from "hooks/auth/logout";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import Logo from "assets/images/logo.png";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ar from "dayjs/locale/ar";
import en from "dayjs/locale/en";
import UnratedTicket from "components/ticket/unrated/unratedTicket";
import { isClient } from "utils/helpers/check-roles";
import ModalOverlay from "components/Modals/ModalOverlay";
import useNotificationsNumber from "hooks/query/notifications/useNotificationsNumber";
import i18next, { t } from "i18next";
import { direction } from "utils/ui/LocaleDirection";

dayjs.extend(relativeTime);

const Header = (props: any) => {
  const queryClient = useQueryClient();
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);
  const notiCon = useRef<any>(null);
  const logoutUser = useLogout();

  const { isMobile } = useWidth();
  const [IsDrawerVisiable, setIsDrawerVisiable] = useState(false);

  const [NotificationsSetting, setNotificationsSetting] = useState<any>({
    page: 1,
    loadingMore: false,
    data: [],
    total: 0,
  });

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const loading = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spin indicator={antIcon} size="small" />
    </div>
  );

  const [isNewsVisible, setIsNewsVisible] = useState(false);
  const openNews = () => {
    setIsNewsVisible(true);
  };
  const [unread, setUnread] = useState<boolean>(false);
  const [Visable, setVisable] = useState<any>(false);
  const Notifications = useNotifications(
    NotificationsSetting.page,
    unread,
    Visable
  );

  const notificationsNumber = useNotificationsNumber();

  const ReadAllNotification = ReadAllNotifications();
  const ReadOne = ReadOneNotification();

  useEffect(() => {
    queryClient.removeQueries("Notifications");
  }, []);

  useEffect(() => {
    if (Notifications.status === "success") {
      let allUsersCompany: any = [];
      let newArr = NotificationsSetting;

      Notifications?.data?.pages &&
        Notifications?.data?.pages[0] !== undefined &&
        Notifications?.data?.pages.forEach((page) => {
          if (page !== null) {
            allUsersCompany = [...allUsersCompany, ...page.data];
            newArr.total = page?.meta?.other;
          }
        });
      newArr.data = allUsersCompany;

      if (
        Notifications?.data?.pages[Notifications?.data?.pages.length - 1]?.data
          .length > 0
      ) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setNotificationsSetting({ ...newArr });
    }
  }, [Notifications?.data?.pages]);

  const handleScroll = () => {
    let newArr = NotificationsSetting;

    let userScrollHeight =
      notiCon.current.clientHeight + notiCon.current.scrollTop;
    let windowBottomHeight = notiCon.current.scrollHeight;
    if (userScrollHeight >= windowBottomHeight) {
      if (!NotificationsSetting.loadingMore) {
        if (newArr.page.toString() === NotificationsSetting.page.toString()) {
          newArr.loadingMore = true;
          newArr.page = newArr.page + 1;
          Notifications.fetchNextPage();
          setNotificationsSetting({ ...newArr });
        }
      }
    }
  };

  const handleLanguageChange = (e: any) => {
    localStorage.setItem("lang", e.key);
    i18next.changeLanguage(e.key).then(() => {
      queryClient.invalidateQueries();
    });
    if (e.key === "ar") {
      document.documentElement.style.setProperty("direction", "rtl");
    } else {
      document.documentElement.style.setProperty("direction", "ltr");
    }
  };

  const languageItems: MenuProps["items"] = [
    {
      key: "ar",
      label: <a>AR</a>,
    },
    {
      key: "en",
      label: <a>EN</a>,
    },
  ];

  const menu = (
    <Menu>
      <NavLink
        className={({ isActive }: any) => (isActive ? "LinkActive" : "")}
        to={{ pathname: `/profile` }}
        onClick={() => setIsDrawerVisiable(false)}
      >
        <Menu.Item style={{ textAlign: "center" }}>{t("profile")}</Menu.Item>
      </NavLink>
      <Menu.Item
        onClick={() => {
          logoutUser.mutateAsync();
          setIsDrawerVisiable(false);
        }}
        className="redbg"
        style={{ textAlign: "center", color: Color.red }}
      >
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  const content = (
    <div
      ref={notiCon}
      onScroll={(e: any) => handleScroll()}
      className="notificationCon"
    >
      {NotificationsSetting?.data.map((Notification: any) => (
        <>
          {(Notification.model_type === "Ticket" ||
            Notification.model_type === "User") && (
            <div
              className={
                Notification.is_read !== null &&
                Notification.is_read.toString() === "1"
                  ? "notificationBody"
                  : "notificationBody active"
              }
            >
              <Link
                to={
                  Notification.model_type === "Ticket"
                    ? `/${isClient(User?.role_id!) ? "c-tickets" : "tickets"}/${
                        Notification.model_id
                      }?not_event=${Notification.pointer_type}`
                    : User?.role_id !== Roles.client &&
                      Notification.model_type === "User"
                    ? `/users/${Notification.model_id}`
                    : ""
                }
              >
                <Badge.Ribbon
                  placement="start"
                  text={`#${
                    isClient(User?.role_id!)
                      ? Notification.public_id
                      : Notification.model_id
                  }`}
                >
                  <div
                    onClick={() => {
                      queryClient.removeQueries("Notifications");
                      setVisable(false);
                    }}
                    className={"notificationCard"}
                  >
                    <div className="title">{Notification.title}</div>
                    <div className="des">{Notification.content}</div>

                    <div
                      className="time"
                      style={{
                        left: i18next.language === "ar" ? "5px" : "",
                        right: i18next.language === "en" ? "5px" : "",
                      }}
                    >
                      {dayjs(Notification.created_at)
                        .locale(i18next.language === "ar" ? ar.name : en.name)
                        .fromNow()}
                    </div>
                  </div>
                </Badge.Ribbon>
              </Link>
              {Notification.is_read !== null &&
                Notification.is_read.toString() === "0" && (
                  <Button
                    className="read"
                    style={{
                      left: i18next.language === "ar" ? "10px" : "",
                      right: i18next.language === "en" ? "10px" : "",
                    }}
                    onClick={() => {
                      ReadOne.mutateAsync(Notification.id).then(() => {
                        Notifications.refetch().then(() => {
                          queryClient.removeQueries("Notifications");
                        });
                      });
                    }}
                    type={"link"}
                    icon={
                      <CheckCircleOutlined style={{ fontSize: "1.2rem" }} />
                    }
                  >
                    <span className="title">{t("mark_as_read")}</span>
                  </Button>
                )}
            </div>
          )}
        </>
      ))}

      {!NotificationsSetting?.loadingMore && (
        <ModalOverlay
          isLoading={Notifications.isLoading}
          isFetching={Notifications.isFetching}
        />
      )}

      {Notifications?.isFetching &&
        NotificationsSetting?.loadingMore &&
        NotificationsSetting?.page !==
          Notifications?.data?.pages[Notifications?.data?.pages.length - 1]
            ?.meta.lastPage! +
            1 &&
        loading}
    </div>
  );

  return (
    <div dir={direction[i18next.language]}>
      {isClient(User?.role_id!) && <UnratedTicket />}
      <div
        className="header"
        style={{
          flexDirection: i18next.language === "en" ? "row-reverse" : "row",
        }}
      >
        {isMobile && (
          <>
            <MenuOutlined
              className="menuIcon"
              onClick={() => setIsDrawerVisiable(true)}
            />
            <Drawer
              open={IsDrawerVisiable}
              onClose={() => setIsDrawerVisiable(false)}
              placement={"left"}
              width={"90%"}
            >
              <div className="adminInfo">
                <img src={User?.avatar} alt="" />
                <div className="cardText">
                  <div className="title">
                    {User?.full_name !== null && User?.full_name}
                  </div>
                  <div className="des">{User?.role_name}</div>
                </div>
              </div>
              <Menu activeKey="home" mode="vertical" className={"header-menu"}>
                {!isClient(User?.role_id!) && (
                  <NavLink
                    className={({ isActive }: any) =>
                      isActive ? "LinkActive" : ""
                    }
                    to={{
                      pathname: "/client-requests",
                    }}
                  >
                    <Menu.Item
                      key="tickets"
                      onClick={() => setIsDrawerVisiable(false)}
                    >
                      {t("requests")}
                    </Menu.Item>
                  </NavLink>
                )}
                {isClient(User?.role_id!) && (
                  <NavLink
                    className={({ isActive }: any) =>
                      isActive ? "LinkActive" : ""
                    }
                    to={{
                      pathname: "/c-tickets",
                    }}
                  >
                    <Menu.Item
                      key="tickets"
                      onClick={() => setIsDrawerVisiable(false)}
                    >
                      {t("tickets")}
                    </Menu.Item>
                  </NavLink>
                )}

                {hasPermission(["browse_technical_profiles"]) && (
                  <NavLink
                    className={({ isActive }: any) =>
                      isActive ? "LinkActive" : ""
                    }
                    to={{
                      pathname: "/technicians",
                    }}
                  >
                    <Menu.Item
                      key="technicians"
                      onClick={() => setIsDrawerVisiable(false)}
                    >
                      {t("technicals")}
                    </Menu.Item>
                  </NavLink>
                )}
                {hasPermission(["browse_companies", "browse_users"]) && (
                  <NavLink
                    className={({ isActive }: any) =>
                      isActive ? "LinkActive" : ""
                    }
                    to={{
                      pathname: "/customers",
                    }}
                  >
                    <Menu.Item
                      key="Customers"
                      onClick={() => setIsDrawerVisiable(false)}
                    >
                      {t("customers")}
                    </Menu.Item>
                  </NavLink>
                )}
                {(User?.role_id === Roles.dispatcher ||
                  User?.role_id === Roles.operation ||
                  User?.role_id === Roles.sales) &&
                  hasPermission(["browse_users"]) && (
                    <NavLink
                      className={({ isActive }: any) =>
                        isActive ? "LinkActive" : ""
                      }
                      to={{
                        pathname: "/users",
                      }}
                    >
                      <Menu.Item
                        key="users"
                        onClick={() => setIsDrawerVisiable(false)}
                      >
                        {t("users")}
                      </Menu.Item>
                    </NavLink>
                  )}
                {User?.role_id === Roles.accountant && (
                  <NavLink
                    className={({ isActive }: any) =>
                      isActive ? "LinkActive" : ""
                    }
                    to={{
                      pathname: "/reports",
                    }}
                  >
                    <Menu.Item
                      key="reports"
                      onClick={() => setIsDrawerVisiable(false)}
                    >
                      {t("reports")}
                    </Menu.Item>
                  </NavLink>
                )}
                {(User?.role_id === Roles.dispatcher ||
                  User?.role_id === Roles.operation ||
                  User?.role_id === Roles.sales) && (
                  <NavLink
                    className={({ isActive }: any) =>
                      isActive ? "LinkActive" : ""
                    }
                    to={{
                      pathname: "/control-panel",
                    }}
                  >
                    <Menu.Item
                      key="control-pane"
                      onClick={() => setIsDrawerVisiable(false)}
                    >
                      <Menu.Item
                        key="control-pane"
                        onClick={() => setIsDrawerVisiable(false)}
                      >
                        {t("control_panel")}
                      </Menu.Item>
                    </Menu.Item>
                  </NavLink>
                )}
                {hasPermission(["browse_settings", "read_settings"]) && (
                  <NavLink
                    className={({ isActive }: any) =>
                      isActive ? "LinkActive" : ""
                    }
                    to={{
                      pathname: "/system-settings",
                    }}
                  >
                    <Menu.Item
                      key="system-settings"
                      onClick={() => setIsDrawerVisiable(false)}
                    >
                      {t("system_configuration")}
                    </Menu.Item>
                  </NavLink>
                )}
              </Menu>
              <div>{menu}</div>
            </Drawer>
          </>
        )}
        {!isMobile && (
          <Dropdown
            disabled={logoutUser.isLoading}
            trigger={["click"]}
            overlay={menu}
            placement="bottomLeft"
          >
            <div className="adminInfo">
              <img src={User?.avatar ? User?.avatar : ""} alt="" />
              <div className="cardText">
                <div className="title">
                  {User?.full_name !== null && User?.full_name}
                </div>
                <div className="des">{User?.role_name}</div>
              </div>
              {logoutUser.isLoading && (
                <Spin
                  style={{ color: "white", marginRight: "8px" }}
                  indicator={<LoadingOutlined />}
                />
              )}
            </div>
          </Dropdown>
        )}
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          {!isMobile && (
            <Menu activeKey="home" mode="inline" className={"header-menu"}>
              {!isClient(User?.role_id!) && (
                <NavLink
                  className={({ isActive }: any) =>
                    isActive ? "LinkActive Link" : "Link"
                  }
                  to={{
                    pathname: "/client-requests",
                  }}
                >
                  <Menu.Item key="client-requests">
                    {t("requests")}
                    {NotificationsSetting?.total !== 0 &&
                      NotificationsSetting?.total?.tickets !== 0 && (
                        <div className="notificationNum">
                          {NotificationsSetting?.total?.tickets}
                        </div>
                      )}
                  </Menu.Item>
                </NavLink>
              )}
              {isClient(User?.role_id!) && (
                <NavLink
                  className={({ isActive }: any) =>
                    isActive ? "LinkActive Link" : "Link"
                  }
                  to={{
                    pathname: "/c-tickets",
                  }}
                >
                  <Menu.Item key="c-tickets">
                    {t("tickets")}
                    {NotificationsSetting?.total !== 0 &&
                      NotificationsSetting?.total?.tickets !== 0 && (
                        <div className="notificationNum">
                          {NotificationsSetting?.total?.tickets}
                        </div>
                      )}
                  </Menu.Item>
                </NavLink>
              )}
              {hasPermission(["browse_technical_profiles"]) && (
                <NavLink
                  className={({ isActive }: any) =>
                    isActive ? "LinkActive Link" : "Link"
                  }
                  to={{
                    pathname: "/technicians",
                  }}
                >
                  <Menu.Item key="technicians">{t("technicals")}</Menu.Item>
                </NavLink>
              )}
              {hasPermission(["browse_technical_profiles"]) && (
                <NavLink
                  className={({ isActive }: any) =>
                    isActive ? "LinkActive Link" : "Link"
                  }
                  to={{
                    pathname: "/customers",
                  }}
                >
                  <Menu.Item key="Customers">{t("customers")}</Menu.Item>
                </NavLink>
              )}
              {(User?.role_id === Roles.dispatcher ||
                User?.role_id === Roles.operation ||
                User?.role_id === Roles.sales) &&
                hasPermission(["browse_users"]) && (
                  <NavLink
                    className={({ isActive }: any) =>
                      isActive ? "LinkActive Link" : "Link"
                    }
                    to={{
                      pathname: "/users",
                    }}
                  >
                    <Menu.Item key="users">
                      {t("users")}
                      {NotificationsSetting?.total !== 0 &&
                        NotificationsSetting?.total?.users !== 0 && (
                          <div className="notificationNum">
                            {NotificationsSetting?.total?.users}
                          </div>
                        )}
                    </Menu.Item>
                  </NavLink>
                )}
              {(User?.role_id === Roles.dispatcher ||
                User?.role_id === Roles.operation ||
                User?.role_id === Roles.sales) && (
                <NavLink
                  className={({ isActive }: any) =>
                    isActive ? "LinkActive Link" : "Link"
                  }
                  to={{
                    pathname: "/control-panel",
                  }}
                >
                  <Menu.Item key="control-panel">
                    {t("control_panel")}
                  </Menu.Item>
                </NavLink>
              )}
              {User?.role_id === Roles.accountant && (
                <NavLink
                  to="/reports"
                  className={({ isActive }: any) =>
                    isActive ? "LinkActive Link" : "Link"
                  }
                >
                  <Menu.Item key="reports">{t("reports")}</Menu.Item>
                </NavLink>
              )}
              {hasPermission(["browse_settings", "read_settings"]) && (
                <NavLink
                  to="/system-settings"
                  className={({ isActive }: any) =>
                    isActive ? "LinkActive Link" : "Link"
                  }
                >
                  <Menu.Item key="system-settings">
                    {t("system_configuration")}
                  </Menu.Item>
                </NavLink>
              )}
              <span className="active-bar"></span>
            </Menu>
          )}
          <Popover
            title={
              <>
                <div>{t("notifications")}</div>
                <span style={{ fontSize: "14px" }}>
                  <Button
                    size="small"
                    type="link"
                    disabled={
                      NotificationsSetting?.total?.notification_number === 0
                    }
                    onClick={() => {
                      ReadAllNotification.mutateAsync().then(() => {
                        Notifications.refetch().then(() => {
                          queryClient.removeQueries("Notifications");
                        });
                      });
                    }}
                    style={{ margin: "0 10px" }}
                    loading={ReadAllNotification.isLoading}
                  >
                    {t("mark_all_as_read")}
                  </Button>
                </span>
                <span style={{ fontSize: "14px" }}>
                  <Switch
                    size="small"
                    checked={unread}
                    disabled={
                      notificationsNumber.data?.data.number_of_notifications ===
                      0
                    }
                    onClick={() => {
                      setUnread(!unread);
                      Notifications.refetch().then(() => {
                        queryClient.removeQueries("Notifications");
                      });
                    }}
                    style={{ margin: "0 10px" }}
                    loading={Notifications.isFetching}
                  />
                  {t("unread_only")}
                </span>
              </>
            }
            className="notification"
            placement={isMobile ? "bottomLeft" : "bottom"}
            content={content}
            trigger="click"
            onOpenChange={() => {
              if (!Visable) queryClient.removeQueries("Notifications");
              setVisable(!Visable);
            }}
            open={Visable}
          >
            <div className="notificationBtn">
              <BellOutlined />
              {notificationsNumber.data?.data.number_of_notifications !== 0 && (
                <div className="notificationNum">
                  {notificationsNumber.data?.data.number_of_notifications}
                </div>
              )}
            </div>
          </Popover>
          <Dropdown
            trigger={["hover"]}
            menu={{
              items: languageItems,
              onClick: handleLanguageChange,
              selectable: true,
            }}
          >
            <Button
              style={{
                margin: "0 1rem",
                backgroundColor: "transparent",
                color: Color.grayAC,
              }}
              onClick={(e) => e.preventDefault()}
            >
              {localStorage.getItem("lang")?.toUpperCase()}
            </Button>
          </Dropdown>
        </div>
        <div className="brand" style={{ position: "relative" }}>
          <NewsIcon onClick={() => openNews()} />
          <Link
            to={{
              pathname: isClient(UserData.Decrypt()?.role_id!)
                ? "/c-tickets"
                : "/",
            }}
          >
            <img className="logo" src={Logo} alt="" />
          </Link>
        </div>
      </div>
      <NewsModal modal={isNewsVisible} close={() => setIsNewsVisible(false)} />
    </div>
  );
};

export default Header;
