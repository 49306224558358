import { Button, Checkbox, Form, Input, Modal, Select, Spin, Tag } from "antd";
import ModalOverlay from "components/Modals/ModalOverlay";
import { useCategoriesList } from "hooks/query/categories/useCategories";
import UpdateService from "hooks/query/services/updateService";
import { t } from "i18next";
import { CategoryModel } from "models/categories";
import { TicketServiceModel } from "models/ticketServices";
import { useEffect, useState } from "react";

const { Option } = Select;
interface UpdateServiceModalProps {
  item: TicketServiceModel;
}
const EditSubCategoryModal = ({ item }: UpdateServiceModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [internalItem, setInternalItem] = useState<TicketServiceModel>();

  const [form] = Form.useForm();
  const [isLinked, setIsLinked] = useState<boolean>(false);
  const { data: categories } = useCategoriesList();
  const { mutateAsync: updateMutatuion, isLoading } = UpdateService();

  const handleClose = () => {
    form.resetFields();
    setIsOpen(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [item, isOpen]);

  const onFinish = (e: any) => {
    const formData = new FormData();
    console.log(e);
    formData.append("name_ar", e.name_ar);
    formData.append("name_en", e.name_en);
    formData.append("cost", e.cost);
    formData.append("_method", "PUT");
    formData.append("is_linked", isLinked ? String(true) : String(false));
    e.categories.forEach((category: any) => {
      if (category !== undefined && typeof category !== "object") {
        formData.append("category_id[]", `${category}`);
      } else {
        formData.append("category_id[]", category.key);
      }
    });
    updateMutatuion({
      id: item?.id!,
      data: formData,
    }).then(() => {
      handleClose();
    });
  };

  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setIsOpen(true);
          form.setFieldsValue("");
          setInternalItem(item);
        }}
      >
        {t("update")}
      </Button>
      <Modal
        key={"editSubCategory"}
        title={t("edit_service")}
        open={isOpen}
        width={400}
        centered
        bodyStyle={{ padding: 20, maxHeight: "420px", overflowY: "scroll" }}
        destroyOnClose
        onCancel={() => handleClose()}
        confirmLoading={isLoading}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            name_ar: item?.name_ar,
            name_en: item?.name_en,
            cost: item?.cost ?? 0,
            categories: item?.categories?.map(
              (categoryOption: CategoryModel) => (
                <Option key={categoryOption.id}>{categoryOption?.name}</Option>
              )
            ),
            is_linked: item?.is_linked,
          }}
          labelCol={{ span: 24 }}
          validateMessages={{
            required: t("please_input") + " ${label}!",
          }}
        >
          <Form.Item
            name={"name_ar"}
            label={t("arabic_name")}
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"name_en"}
            label={t("english_name")}
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"cost"}
            label={t("cost")}
            rules={[{ required: true }]}
            required
          >
            <Input disabled={isLinked} />
          </Form.Item>
          <Form.Item name={"is_linked"}>
            <Checkbox
              value={isLinked}
              onChange={(e) => {
                setIsLinked(e.target.checked);
              }}
            >
              {t("linked_cost_to_level")}
            </Checkbox>
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            required
            label={t("category_type")}
            name={"categories"}
          >
            <Select mode="multiple" allowClear style={{ width: "100%" }}>
              {categories?.data?.map((categoryOption) => (
                <Option key={categoryOption?.id}>{categoryOption?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditSubCategoryModal;
