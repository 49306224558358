import { FetchClassifications } from "services/classifications";
import { useQuery } from "react-query";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { ClassificationModel } from "models/companies";

export default function useClassifications() {
  const queryKey = ["classifications"];
  return {
    ...useQuery<ApiResult<ClassificationModel[]>, ApiError>(
      queryKey,
      () => FetchClassifications(),
      {
        staleTime: 10000,
      }
    ),
  };
}
