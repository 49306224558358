import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, MenuProps, Row, Space } from "antd";
import Logo from "assets/images/logo.png";
import i18next from "i18next";
import { useQueryClient } from "react-query";
import { direction } from "utils/ui/LocaleDirection";

const Container = ({ children }: any) => {
  const queryClient = useQueryClient();

  const handleLanguageChange = (e: any) => {
    console.log(e.key);
    localStorage.setItem("lang", e.key);
    i18next.changeLanguage(e.key).then(() => {
      queryClient.clear();
    });
  };

  const languageItems: MenuProps["items"] = [
    {
      key: "ar",
      label: <a>AR</a>,
    },
    {
      key: "en",
      label: <a>EN</a>,
    },
  ];

  return (
    <div dir={direction[i18next.language]}>
      <Row justify={"center"} align={"middle"} style={{ height: "100vh" }}>
        <Col lg={22} span={23}>
          <Row justify="center">
            <Col xxl={6} xl={8} lg={11} md={13} span={24}>
              <div className="login">
                <div className="FormCon">
                  <Dropdown
                    trigger={["hover"]}
                    menu={{
                      items: languageItems,
                      onClick: handleLanguageChange,
                      selectable: true,
                    }}
                  >
                    <a
                      style={{ margin: "0 1rem" }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <Space>
                        {localStorage.getItem("lang")?.toUpperCase()}
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                  <img
                    style={{
                      margin: "0 auto",
                      display: "block",
                      maxWidth: "220px",
                    }}
                    src={Logo}
                    alt=""
                  />

                  <>{children}</>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Container;
