/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "antd/dist/reset.css";
import "antd-country-phone-input/dist/index.css";
import "styles/main.scss";
import { Button, ConfigProvider, notification } from "antd";
import eventManager, { EVENT_UNAUTHORIZED } from "utils/event-manager";
import { useQueryClient } from "react-query";
import InstallWebapp from "utils/helpers/serviceWorker";
import { getTokenAsync } from "firebaseInit";
import { deleteToken, onMessage } from "firebase/messaging";
import { messaging } from "firebaseInit";
import { useAppDispatch, useAppSelector } from "store";
import CheckUser from "hooks/query/users/checkUser";
import Color from "utils/helpers/export-sass-colors";
import { checkAuth, checkUser } from "state";
import { UserModel } from "models/users";
import { LOGOUT } from "actions/types";
import { Token, UserData } from "utils/helpers/encrypt-decrypt-user";
import IndexRoutes from "routes/routes";
import notfiSound from "assets/sound/notification.mp3";
import IzoneLogo from "assets/images/izoneLogo.ico";
import AR from "antd/locale/ar_EG";
import EN from "antd/locale/en_US";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function App() {
  const queryClient = useQueryClient();

  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    const user: UserModel | null = UserData.Decrypt();
    if (Token.Get() !== null && isLoggedIn) {
      getTokenAsync();
    } else if (Token.Get() !== null && !isLoggedIn && user) {
      dispatch(checkUser({ user }));
    } else {
      dispatch(checkAuth({ type: LOGOUT }));
      Token.Remove();
      UserData.Remove();
    }
  }, []);
  const [online, setOnline] = useState<boolean>(true);
  useEffect(() => {
    setInterval(() => {
      if (navigator.onLine) setOnline(true);
      else setOnline(false);
    }, 5000);
  }, []);
  const icon = <img alt="" src={IzoneLogo} width={30} />;

  useEffect(() => {
    if (!online)
      notification.open({
        type: "info",
        message:
          i18next.language === "ar"
            ? "انت غير متصل بالشبكة."
            : "You are not connected.",
        description:
          i18next.language === "ar"
            ? "تأكد من اتصالك!"
            : "Check your internet connection",
        key: "offline",
        icon,
        closeIcon: <span style={{ display: "none" }} />,
        placement: "bottomLeft",
        duration: 99999,
      });
    else notification.destroy("offline");
  }, [online]);

  notification.config({ maxCount: 3 });
  const sound = new Audio(notfiSound);
  sound.volume = 0.07;
  /**
   * disable for localhost
   */

  onMessage(messaging, (payload: any) => {
    sound.play();
    const arr = payload.data.pointer_type.split("\\");
    const pointerType = arr[arr.length - 1];
    notification.open({
      duration: 20,
      message: payload.notification.title,
      description: payload.notification.body,
      key: "message-not",
      btn: (pointerType === "Ticket" || pointerType === "TicketReport") && (
        <div style={{ textAlign: "left" }}>
          <Button
            onClick={() => {
              navigate(`/tickets/${payload.data.pointer_id}`);
              notification.destroy("message-not");
            }}
            type="primary"
            style={{ backgroundColor: Color.blue }}
          >
            {t("view")}
          </Button>
        </div>
      ),
      style: {
        direction: i18n.language === "ar" ? "rtl" : "ltr",
      },
    });
    queryClient.invalidateQueries("tickets");
    queryClient.invalidateQueries("NotificationsNumber");
    queryClient.invalidateQueries("clientnotes");
    queryClient.invalidateQueries("comments");
    queryClient.invalidateQueries("Reports");
    queryClient.invalidateQueries("Report");
  });

  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  CheckUser(true);

  useEffect(() => {
    if (Token.Get() !== null && !isLoggedIn)
      eventManager.on(EVENT_UNAUTHORIZED, () => {
        dispatch(checkAuth({ type: LOGOUT }));
        Token.Remove();
        UserData.Remove();
      });
  }, [eventManager]);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("lang", "ar");
    }
    if (lang === "ar") {
      document.documentElement.style.setProperty("direction", "rtl");
    } else {
      document.documentElement.style.setProperty("direction", "ltr");
    }
  }, []);

  return (
    <ConfigProvider
      locale={i18n.language === "en" ? EN : AR}
      direction={i18n.language === "ar" ? "rtl" : "ltr"}
      theme={{ token: { colorPrimary: Color.blue } }}
    >
      <div className="App">
        {/* <InstallWebapp /> */}
        <IndexRoutes />
      </div>
    </ConfigProvider>
  );
}

export default App;
