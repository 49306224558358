const Footer = () => {
  return (
    <div className="footer">
      <div className="version">Version {process.env.REACT_APP_VERSION}</div>
      <div className="copyright">
        <a
          href={"https://cyberx.aigate.me/"}
          rel="noreferrer"
          target={"_blank"}
        >
          CYBER X
        </a>{" "}
        2022-2023©
      </div>
    </div>
  );
};

export default Footer;
