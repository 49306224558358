import Forbidden from "components/ErrorPages/403";
import { hasPermission } from "components/permissionsGate";

import { UserData } from "utils/helpers/encrypt-decrypt-user";

interface protectedRouteProps {
  permissions: any;
  children: any;
  roles?: any[];
}

const ProtectedRoute = (props: protectedRouteProps) => {
  const roleCanVisit = props.roles?.includes(UserData.Decrypt()?.role_id);
  if (
    (!hasPermission(props.permissions) &&
      hasPermission(props.permissions) !== undefined) ||
    (!roleCanVisit && props.roles !== undefined)
  ) {
    return <Forbidden />;
  }
  return props.children;
};

export default ProtectedRoute;
