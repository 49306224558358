import { t } from "i18next";

export const Roles = {
  admin: 1,
  user: 2,
  dispatcher: 3,
  technical: 4,
  accountant: 5,
  distributor: 6,
  client: 7,
  sales: 8,
  operation: 9,
};

export const Ticket = {
  create: "0",
  pending: "1",
  priced: "9",
  active: "2",
  done: "3",
  approved: "4",
  denied: "5",
  paid: "10",
  partialPaid: "11",
};

export const TicketKeyName = {
  create: () => t("create"),
  pending: () => t("pending"),
  priced: () => t("priced"),
  active: () => t("active_only"),
  done: () => t("done"),
  approved: () => t("approved"),
  denied: () => t("denied"),
  paid: () => t("paid"),
  paidClient: "مدفوعة",
  partialPaid: () => t("partially_paid"),
};

export const ClientRequestKeyName = {
  create: () => t("create"),
  pending: () => t("pending"),
  priced: () => t("priced"),
  active: () => t("active"),
  done: () => t("done"),
  approved: () => t("approved"),
  denied: () => t("denied"),
  paid: () => t("paid"),
  paidClient: "مدفوع",
  partialPaid: () => t("partially_paid"),
};
